<template>
    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        <b-row class="match-height">
            <b-col lg="12" md="12">
        
                <b-form @submit.prevent="formSubmit">
                    <b-card title="">

                            <b-col md=12>
                                <div class="demo-inline-spacing float-right "> 
                                    <b-button variant="danger" class="mt-0 mb-1" :to="{ name: 'defect-type'}" v-if="disabledOptions == false">
                                        <feather-icon icon="FramerIcon" class="mr-25"/>
                                        <span>Defect Type</span>
                                    </b-button>
                                </div>
                            </b-col>


                        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                            <div class="alert-body">
                                {{error_message}}
                            </div>
                        </b-alert>

                        <b-row>
                            <b-col md="12">
                                <h4 class="card-title float-left"> Conservancy Supervisor Daily Report </h4>
                            </b-col>
                        </b-row>
                        
                        <b-row>
                            <b-col md="12">
                                <b-row>

                                    <b-col md="4">
                                        <b-form-group label="Project Site" class="required">
                                            <b-form-select v-model="form.site" @change="siteChange(); getDefectTypes();" :disabled="disabledOptions">
                                                <b-form-select-option value="" disabled>Select</b-form-select-option>
                                                <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                                            </b-form-select>
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="4">
                                        <b-form-group label="Property Division" class="required">
                                            <b-form-input autocomplete="off" placeholder="3 to 50 characters" v-model="form.property_division" :disabled="disabledOptions"/>
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="4">
                                        <b-form-group label="Submitted By" class="required">
                                            <b-form-input autocomplete="off" placeholder="3 to 50 characters" v-model="form.submitted_by" :disabled="disabledOptions"/>
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="4">
                                        <b-form-group label="Number of Workers (NTS)">
                                            <b-form-input autocomplete="off" placeholder="Enter Number" v-model="form.number_of_workers_nts"/>
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="4">
                                        <b-form-group label="Number of Workers (SP)">
                                            <b-form-input autocomplete="off" placeholder="Enter Number" v-model="form.number_of_workers_sp"/>
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="4">
                                        <b-form-group label="Number of Workers (Local)">
                                            <b-form-input autocomplete="off" placeholder="Enter Number" v-model="form.number_of_workers_local"/>
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="4">
                                        <b-form-group label="Submitted Date" class="required">
                                            <b-form-datepicker v-model="form.submitted_date" :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }" locale="en" :disabled="disabledOptions"/>
                                        </b-form-group>
                                    </b-col>
                                    
                                </b-row>

                            </b-col>
                        </b-row>

                    </b-card>

                    <!-- All Blocks -->
                    <b-card>
                        <b-row class="mb-0">
                            <b-col md="4">
                                <b-form-group label="1st Block" class="required">
                                    <b-form-input autocomplete="off" placeholder="Enter 1st Block" v-model="form.block_no_1"/>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="2nd Block" class="required">
                                    <b-form-input autocomplete="off" placeholder="Enter 2nd Block" v-model="form.block_no_2"/>
                                </b-form-group>
                            </b-col>
                            <b-col md="4">
                                <b-form-group label="3rd Block" class="required">
                                    <b-form-input autocomplete="off" placeholder="Enter 3rd Block" v-model="form.block_no_3"/>
                                </b-form-group>
                            </b-col>
                            
                        </b-row>
                    </b-card>

                    <!-- All Defect Type -->
                    <b-card>
                        <b-row class="mb-1 mt-1">
                            <b-col md="1">
                                <h5> # </h5>
                            </b-col>
                            <b-col md="1">
                                <h5> Defect Image </h5>
                            </b-col>
                            <b-col md="2">
                                <h5> Defect Item </h5>
                            </b-col>
                            <b-col md="2">
                                <h5> Defect Type </h5>
                            </b-col>
                            <b-col md="2">
                                <h5> Remark For Block {{form.block_no_1}}</h5>
                            </b-col>
                            <b-col md="2">
                                <h5> Remark For Block {{form.block_no_2}} </h5>
                            </b-col>
                            <b-col md="2">
                                <h5> Remark For Block {{form.block_no_3}} </h5>
                            </b-col>
                        </b-row>
                        <hr>
                        <div v-for="(dt, index) in form.defect_types" :key="index">
                            <b-row class="mb-0">
                                <b-col md="1" class="mb-1"> {{index + 1}} </b-col>
                                <b-col md="1" class="mb-1"> 
                                    <img class="rounded img-fluid" :src="dt.defect_image">
                                </b-col>
                                <b-col md="2" class="mb-1"> 
                                    <b-form-input autocomplete="off" placeholder="" v-model="dt.defect_name"/>
                                </b-col>
                                <b-col md="2" class="mb-1">
                                    <v-select v-model="dt.defect_type_selected" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :options="dt.defect_type" placeholder="Select"/>
                                </b-col>
                                <b-col md="2" class="mb-1">
                                    <b-form-input autocomplete="off" placeholder="" v-model="dt.remark_for_block_1"/>
                                </b-col>
                                <b-col md="2" class="mb-1">
                                    <b-form-input autocomplete="off" placeholder="" v-model="dt.remark_for_block_2"/>
                                </b-col>
                                <b-col md="2" class="mb-1">
                                    <b-form-input autocomplete="off" placeholder="" v-model="dt.remark_for_block_3"/>
                                </b-col>
                            </b-row>
                            <hr v-if="form.defect_types.length != (index + 1)">
                        </div>
                    </b-card>

                    <!-- All Cleaning Schedule -->
                    <b-card title="Cleaning Schedule">
                        <b-row class="mb-1 mt-1">
                            <b-col md="1">
                                <h5> # </h5>
                            </b-col>
                            <b-col md="5">
                                <h5> Description of Works </h5>
                            </b-col>
                            <b-col md="2">
                                <h5 class="text-center"> Works Done at Blocks </h5>
                            </b-col>
                            <b-col md="4">
                                <h5> Remarks </h5>
                            </b-col>
                        </b-row>
                        <hr>
                        <div v-for="(cs, ind) in form.cleaning_schedule" :key="ind">
                            <b-row class="mb-0">
                                <b-col md="1" class="mb-1"> {{cs.s_no}} </b-col>
                                <b-col md="5" class="mb-1"> 
                                    <b-form-input autocomplete="off" placeholder="" v-model="cs.description_of_works"/>
                                </b-col>
                                <b-col md="2" class="mb-1"> 
                                    <b-row>
                                        <b-col offset="5" cols="6">
                                            <b-form-checkbox v-model="cs.works_done_at_blocks" value="yes" unchecked-value="no" class="custom-control-success"/>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col md="4" class="mb-1">
                                    <b-form-input autocomplete="off" placeholder="" v-model="cs.remarks"/>
                                </b-col>
                            </b-row>
                            <hr v-if="form.cleaning_schedule.length != cs.s_no">
                        </div>
                    </b-card>
                    
                    <!-- supervisor signature -->
                    <b-card>

                        <b-row>

                            <!-- signature structure  -->
                            <b-col cols="12" >
                                <h4 class="text-center pb-2 align_start_mobile">Signature <span style="color:red">*</span></h4>
                                <center class="align_start_mobile full_height_width_img_mobile">
                                    <b-media @click="openSignature('supervisor')" v-if="supervisorSign.image == null">                                     
                                        <b-avatar ref="supervisorS" :src="doc_icon" variant="light-info" size="200px" rounded />

                                        <div class="d-flex flex-wrap">
                                            <input ref="supervisorSInput" type="file" class="d-none" @input="supervisorImageUpload" >
                                        </div>
                                    </b-media>
                                    <img :src="supervisorSign.image" v-if="supervisorSign.image != null" width="200px" class="rounded" height="180" style="background: #ffffff">
                                </center>
                                
                                <center class="mb-2">
                                    <b-button size="sm" variant="danger" class="mt-1 px-2 py-1" v-if="supervisorSign.image != null" @click="removeSign('supervisor')" :disabled="disabledOptions"> 
                                        <feather-icon icon="XIcon" class="mediumSize text-white"/>
                                    </b-button>
                                </center>
                            </b-col>

                        </b-row>
                    
                        <b-row>
                            <b-col class="daily_report_sign">

                                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" class = "mr-1" @click="goBack()">
                                    Cancel
                                </b-button>

                                <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" variant = "danger" class = "mr-1" @click="discardItem(form.id)" v-if="form.id != null && typeof $route.params.id == 'undefined'">
                                    Discard Draft
                                </b-button>

                                <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "primary" class = "mr-1" @click="form.status = 'draft'" :disabled="form.site == ''" v-if="typeof $route.params.id == 'undefined'">
                                    {{ form.id == null ? 'Save as Draft' : 'Update Draft'}}
                                </b-button>

                                <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "warning" class = "mr-1" @click="form.status = 'completed'">
            				        {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Save' }}
                                </b-button>

            <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            v-if="typeof $route.params.id != 'undefined' && ledit == false"
            @click="openSignature('approver')"
          >
            {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Approve' }}
          </b-button>

                            </b-col>
                        </b-row>
                    </b-card>

                </b-form>

            </b-col>
        </b-row>

		    <b-modal
        id="signatureModel"
        ref="signatureModel"
        centered
        title="Signature"
        no-close-on-backdrop
        hide-footer
        @hide="hideSignature"
        @show="hideSignature"
      >
      
      <div v-if="show_default == true">
        <b-row>

          <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
            <center>
              <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
            </center>

          </b-col>
        </b-row>
        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">

          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'admin' ? false : true"
              :disabled-date="disabledDate"
              ></date-picker>


          </b-col>
        </b-row>

        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <b-form-group
                label="Remarks"
                class=""
              >
                <b-form-textarea
                  placeholder=""
                  rows="3"
                  v-model="approve_remark"
                />
              </b-form-group>
          </b-col>
        </b-row>

          <b-row class="mt-2">
          <b-col md="6">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  @click="show_default = false"
                >
                <span class="text-nowrap">Add New Signature</span>
              </b-button>
            </b-col>

            <b-col md="6">
              <b-button
                  variant="warning"
                  class="mt-0 float-right w-100"
                  @click="saveDefault()"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>

      <div v-else>
        <b-row>
          <b-col md="12">
            <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
            />

          </b-col>
        </b-row>
          <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'admin' ? false : true"
              :disabled-date="disabledDate"
              ></date-picker>


          </b-col>
        </b-row>

        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <b-form-group
                label="Remarks"
                class=""
              >
                <b-form-textarea
                  placeholder=""
                  rows="3"
                  v-model="approve_remark"
                />
              </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-2">

          <b-col md="3" v-if="typeof $route.params.id == 'undefined'">
            <b-button
                variant="primary"
                class="mt-0 w-100"
                @click="uploadSign()"
              >
              <span class="text-nowrap">Upload</span>
            </b-button>
          </b-col>

          <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  v-if="having_default == true"
                  @click="show_default = true"
                >
                <span class="text-nowrap">Default</span>
              </b-button>
            </b-col>


          <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

              <b-button
                  variant="danger"
                  class="mt-0 w-100"
                  @click="clearSignature"
                >
                <span class="text-nowrap">Clear</span>
              </b-button>
            </b-col>

            <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">
              <b-button
                  variant="warning"
                  class="mt-0 w-100"
                  @click="saveSignature"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>
      </b-modal> 

    </div>
</template>
<script>
    import {
        BFormTextarea, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile,BFormDatepicker,BBreadcrumb
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive';
    import { GET_API, POST_API } from "../../../store/actions.type";
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker';
    import 'vue2-timepicker/dist/VueTimepicker.css';
    import vSelect from 'vue-select';
    import Bus from "../../../event-bus";
    import Datepicker from 'vuejs-datepicker';
    var moment = require('moment-timezone');
    import DatePicker from 'vue2-datepicker';
	import 'vue2-datepicker/index.css';

    export default {
        components: {
            BFormTextarea, BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, VueTimepicker, vSelect, BFormFile, BFormDatepicker,BBreadcrumb, DatePicker
        },

        directives: {
            Ripple,
        },

        data() {
            return {        
                error_message:null,
                showDismissibleAlert:false,
                draftData:null,
                sites : null,

                form :{
                    id                      : null,
                    site                    : '',
                    submitted_by            : this.$store.getters.currentUser.full_name,
                    submitted_date          : moment().tz('Asia/Singapore').format('YYYY-MM-DD'),
                    number_of_workers_nts   : 0,
                    number_of_workers_sp    : 0,
                    number_of_workers_local : 0,
                    defect_types            : [],
                    property_division       : null,
                    cleaning_schedule       : this.getCleaningSchedule(),
                    block_no_1              : null,
                    block_no_2              : null,
                    block_no_3              : null,
                    status                  :'completed',
                    title                   : 'Conservancy Supervisor Daily Report'
                },

                // signature vars
                doc_icon: require('@/assets/images/doc.png'),
                supervisorSign : {
                    image:null,
                    name:'',
                    type: 'supervisor',
                    default:'no'
                },
                openedSignModel:null,

                flag: false,
                tempSign : {
                    image:null,
                    name:'',
                },
      			show_default:false,
      			having_default:false,
				disabledOptions:false,
      			approve_signature_time:null,
                approve_remark:'',
                ledit : false,
                signature:{image:null,name:'',default:'no'},
                crew_signature : {
                    image:null,
                    name:'',
                    type: 'supervisor',
                    default:'no'
                },
            }
        },

        methods: {
            formSubmit(){
                if (this.form.status == 'completed') {
                    this.decisionAlert('Are you sure want to submit this report ?')
                    .then(result => {
                      if (result.value) {
                        this.submitFunc();            
                      }
                    })
                }else{
                    this.submitFunc();
                }
            },
            submitFunc(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        form_id:this.$route.params.id,
            			approve_signature: this.crew_signature,
                        items : this.form,
                        approve_signature_time: this.approve_signature_time,
                        supervisorSign : this.supervisorSign,
                        approve_remark: this.approve_remark,
                        role:this.$store.getters.currentUser.role
                    },
                    api : '/api/add-conservancy-supervisor-daily-report'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message        = this.$store.getters.getErrors;
                        //this.showDismissibleAlert = true;

                        //window.scrollTo(0,0);
                        this.errorAlertCF()
                    } else {
                        this.showDismissibleAlert = false;

                        var data = this.$store.getters.getResults.data;

                        this.successAlert().then((result) => {
                            
                            if (this.sites && this.sites.length == 1) {
                                this.form.site = this.sites[0]._id;
                                this.siteChange();
                            }else{
                                this.form.site = '';
                                this.refreshData();
                                this.siteChange();    
                            }

                            
                            if(this.form.status == 'completed'){
                                // this.getDefectTypes();
                            }
                            if (data != null) {
                                window.open(data, '_blank');
                            }

                            localStorage.setItem('routeCFCheck','no')

                            Bus.$emit('counter_update');
                            
                            if(this.$route.params.id){
                                this.$router.push({name:'conservancy-supervisor-daily-report-pdf'})
                            }else{
                            this.$router.push({ name:'custom-forms' })
                }
                        });
                    }
                });
            },
            
            allSites(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        role:this.$store.getters.currentUser.role,
                        om_sites:this.$store.getters.currentUser.om_sites,
                    },
                    api: '/api/all-sites'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        this.sites = data;

						if (this.sites.length == 1 && typeof this.$route.params.id == 'undefined') {
                            this.form.site = this.sites[0]._id;
                            this.siteChange();
                            this.getDefectTypes();
                        }

                        return this.sites;
                    }
                });
            },
            
            getDefectTypes(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        // site:this.form.site
                    },
                    api: '/api/all-defect-type'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;

                        this.form.defect_types = (this.form.site != null && this.form.site != '') ? (this.draftData ? this.draftData.defect_types : data) : [];
                        
                    }
                });
            },

            supervisorImageUpload(event){
                var input = event.target;
                var files = event.target.files

                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.readAsDataURL(input.files[0]);
                    var image = input.files[0];

                    if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
                        Swal.fire({
                            position: 'center',
                            icon: 'warning',
                            title: 'Please upload .jpg, .jpeg, .jpg images only',
                            showConfirmButton: false,
                            timer: 1500
                        });

                        this.$refs.supervisorSInput.value=null;

                    } else if (image.size > 2097152) {
                        Swal.fire({
                            position: 'center',
                            icon: 'warning',
                            title: 'Maximum 2 MB files allowed to be upload.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.$refs.supervisorSInput.value=null;
                    } else {
                        reader.onload = (e) => {
                            this.supervisorSign.image = e.target.result;
                            this.supervisorSign.name = image.name;
                            this.supervisorSign.type =  'supervisor';
                            this.supervisorSign.default = 'no';
                        }
                    }
                }
            },
                    
            removeSign(sign){
                this.supervisorSign.image = null;
                this.supervisorSign.name = '';
                this.supervisorSign.default = 'no';
            },

            siteChange(){  

                this.form.site != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');
              
                this.getdraftData()
                .then(() => {
                    if (this.draftData == null) {
                        this.refreshData();
                        // this.getDefectTypes();
                        this.getWorkerCount();
                    }else{
                        this.form.id = this.draftData.id;
                        this.form.site = this.draftData.site;
                        this.form.submitted_by = this.draftData.submitted_by;
                        this.form.number_of_workers_nts = this.draftData.number_of_workers_nts;
                        this.form.number_of_workers_sp = this.draftData.number_of_workers_sp;
                        this.form.number_of_workers_local = this.draftData.number_of_workers_local;
                        this.form.status = this.draftData.status;
                        this.form.defect_types = this.draftData.defect_types;
                        this.form.property_division = this.draftData.property_division;
                        this.form.submitted_date = this.draftData.submitted_date;
                        this.form.cleaning_schedule = this.draftData.cleaning_schedule;
                        this.form.block_no_1 = this.draftData.block_no_1;
                        this.form.block_no_2 = this.draftData.block_no_2;
                        this.form.block_no_3 = this.draftData.block_no_3;

                        if(!this.draftData && !this.draftData.crew_signature_image){
                            this.supervisorSign = this.draftData.crew_signature;
                        } else {
                            this.supervisorSign = {
                                image : this.tempSign.image,
                                name : 'signature.png',
                                type :   'supervisor',
                                default:'yes'
                            }
                        }
                    } 
                });
            },

            getWorkerCount(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        site:this.form.site != '' ? this.form.site : null,
                    },
                    api: '/api/get-worker-count'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        if(data){
                            this.form.number_of_workers_nts = data.number_of_workers_nts;
                            this.form.number_of_workers_sp = data.number_of_workers_sp;
                            this.form.number_of_workers_local = data.number_of_workers_local;
                        }
                    }
                });
            },
            
            getdraftData(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        site:this.form.site != '' ? this.form.site : null,
                    },
                    api: '/api/draft-conservancy-supervisor-daily-report-data'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        this.draftData = data;
                        
                        return this.draftData;
                    }
                });
            },

            discardItem(id){
                var msg = 'Are you sure want to discard this record?';

                this.decisionAlert(msg)
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                id     : id,
                                status : 'deleted'
                            },
                            api : "/api/change-status-conservancy-supervisor-daily-report-data",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;

                                this.showAlert();
                            } else {
                                this.successAlert()
                                .then(() => {
                                    this.flag = true;
                                    this.refreshData();
                                    // this.getDefectTypes();
                                    localStorage.setItem('routeCFCheck','no')
                                    // this.$router.go(-1);
                                    this.$router.push({ name:'custom-forms' })
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            },

            refreshData(){
                if(this.flag){
                    this.form.site = '';
                    this.flag = false;
                    this.form.id = null;
                }
                if(!this.$route.params.id)
                {
                this.form.submitted_by = this.$store.getters.currentUser.full_name;
                this.form.number_of_workers_nts = 0;
                this.form.number_of_workers_sp = 0;
                this.form.number_of_workers_local = 0;
                //this.form.defect_types = [];
                this.form.property_division = null;
                this.form.submitted_date = moment().tz('Asia/Singapore').format('YYYY-MM-DD');
                this.form.cleaning_schedule = this.getCleaningSchedule();
                this.form.block_no_1 = null;
                this.form.block_no_2 = null;
                this.form.block_no_3 = null;
                }

                if(!this.draftData){
                    this.supervisorSign = {
                        image: this.tempSign.image,
                        name:'signature.png',
                        type: 'supervisor',
                        default:'yes'
                    };
                } else {
                    this.supervisorSign = {
                        image:null,
                        name:'',
                        type: 'supervisor',
                        default:'no'
                    };
                }
                
                this.openedSignModel=null;
            },
            
            getCleaningSchedule(){

                var data = [
                    {
                        s_no: 1,
                        description_of_works: 'Bin Chute-flush / machine wash',
                        works_done_at_blocks: 'no',
                        remarks: ''
                    }, {
                        s_no: 2,
                        description_of_works: 'Notice Boards - Wipe and Clean',
                        works_done_at_blocks: 'no',
                        remarks: ''
                    }, {
                        s_no: 3,
                        description_of_works: 'Litter bins-wash',
                        works_done_at_blocks: 'no',
                        remarks: ''
                    }, {
                        s_no: 4,
                        description_of_works: 'Block / Car park - washing',
                        works_done_at_blocks: 'no',
                        remarks: ''
                    }, {
                        s_no: 5,
                        description_of_works: 'Light diffusers - Wipe and Clean',
                        works_done_at_blocks: 'no',
                        remarks: ''
                    }, {
                        s_no: 6,
                        description_of_works: 'Corridors/lift lobbies/void deck/staircase - scrub / wash',
                        works_done_at_blocks: 'no',
                        remarks: ''
                    }, {
                        s_no: 7,
                        description_of_works: 'Perimeter drains - Desilt / flush',
                        works_done_at_blocks: 'no',
                        remarks: ''
                    }, {
                        s_no: 8,
                        description_of_works: 'Open areas, road / kerb, playground /games courts - Machine wash',
                        works_done_at_blocks: 'no',
                        remarks: ''
                    }, {
                        s_no: 9,
                        description_of_works: 'Clean Pump/Elect. switch rooms',
                        works_done_at_blocks: 'no',
                        remarks: ''
                    }, {
                        s_no: 10,
                        description_of_works: 'Polish metal parts - lift door frame/car/letter box',
                        works_done_at_blocks: 'no',
                        remarks: ''
                    }, {
                        s_no: 11,
                        description_of_works: 'Handrails-wipe and clean',
                        works_done_at_blocks: 'no',
                        remarks: ''
                    }, {
                        s_no: 12,
                        description_of_works: 'Trap/Hatch door - check condition and locked',
                        works_done_at_blocks: 'no',
                        remarks: ''
                    }
                ];

                return this.form && this.form.site ? data : [];
            },
            useDefault(){

              return this.$store.dispatch(POST_API, {
                   data:{
                     id:this.$store.getters.currentUser._id
                   },
                   api: '/api/get-default-signature'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        
                        if (data == null || data.default_signature == null) {
                          
                          this.having_default = false;
                          
                        }else{

                          this.tempSign = {
                            image : data.default_signature,
                            name : 'siganture.png',
                          }

                            this.supervisorSign = {
                                image:data.default_signature,
                                name:'signature.png',
                                type: 'supervisor',
                                default:'yes'
                            };

                          this.having_default = true;
                        }
                    }
                });
            },
            uploadSign(){
              
              //if (this.openedSignModel == 'supervisor') {

                 this.$refs['supervisorSInput'].click();
              //}

              /*if (this.openedSignModel == 'inspected_by') {

                 this.$refs['inspectedSInput'].click();
              }*/

              this.$refs['signatureModel'].hide();

            },
            openSignature(type){
            this.approve_signature_time = moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY HH:mm');
              if (this.openedSignModel == null) {

                  if (this.having_default == true) {
                      this.show_default = true;
                  }else{
                    this.show_default = false;
                  }

                  this.$refs['signatureModel'].show();
                  this.openedSignModel = type;
              }
            },
            hideSignature(){
              this.openedSignModel = null;
            },
            clearSignature(){
              this.$refs.signaturePad.clearSignature();
            },
            saveSignature(){
              const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
              
              if (isEmpty) {
                Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Signature is required',
                  showConfirmButton: false,
                  timer: 1500
                })
              } else {
                if (this.openedSignModel == 'supervisor') {
                    this.supervisorSign.image = data;
                    this.supervisorSign.name = 'signature.png';
                    this.supervisorSign.type = 'supervisor';
                    this.supervisorSign.default = 'no';
                } else {
                    this.crew_signature.image = data;
                    this.crew_signature.name = 'siganture.png';
                    this.crew_signature.type = 'supervisor';
                    this.crew_signature.default = 'no';
                    this.form.status = 'approved';
                    this.submitFunc();
                }
                this.$refs['signatureModel'].hide();
              }
            },
            saveDefault(){
                if (this.openedSignModel == 'supervisor') {
                    this.supervisorSign = {
                        image : this.tempSign.image,
                        name : 'signature.png',
                        default:'yes'
                    }
                }else{
                    this.crew_signature = {
                        image : this.tempSign.image,
                        name : 'signature.png',
                        default:'yes',
                        type:'supervisor'
                    }
                    this.form.status = 'approved';
                    this.submitFunc();
                }      
                this.$refs['signatureModel'].hide();
            },
  breadCrumb(){
    if(this.$route.params.id){

        var item = [{
          to:{name:'client-dashboard'},
          text: 'Dashboard',
        },{
          to:null,
          text: 'Reports',
        },{
          to:{name:'custom-forms-dashboard'},
          text: 'Form Dashboard',
        },{
          to:{name:'custom-forms-reports'},
          text:'Custom Forms'
        },{
          to:{name:'conservancy-supervisor-daily-report-pdf'},
          text:'Conservancy Supervisor Daily Report'
        },{
          to:null,
          text:'Edit',
          active:true
        }];
        return this.filterReportBreadCrum(item);

      }else{
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:{name:'custom-forms'},
        text: 'Custom Forms',
      },{
        to:null,
        text:'Conservancy Supervisor Daily Report',   
        active:true             
      }];
      }
      return item;
    },
        goBack(){
            
            if (this.form.site != '') {
                var msg = 'Do you want to Go back without saving your report?';

                this.decisionAlert(msg)
                .then(result => {
                  if (result.value) {
                    localStorage.setItem('routeCFCheck','no')
                        
                    // this.$router.go(-1);
					if(this.$route.params.id){
             		     //console.log('1');
                         this.$router.push({name:'conservancy-supervisor-daily-report-pdf'})
            		}else{
						//console.log('2');
                        this.$router.push({ name:'custom-forms' })
					}

                  }
                })
                
            }else{
                
                this.$router.push({ name:'custom-forms' })

            }
        },
        getFormDetails(){
            return this.$store.dispatch(POST_API, {
                data:{
                    id:this.$route.params.id
                },
                api: '/api/get-csdr-report-detail'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    var data  = this.$store.getters.getResults.data;

                    this.form.id = data._id;
                    this.form.site = data.site;
                    this.form.submitted_by = data.submitted_by;
                    this.form.property_division = data.property_division;
                    this.form.submitted_date = data.submitted_date;
                    this.form.status = data.status;

                    this.form.block_no_1 = data.block_no_1;
                    this.form.block_no_2 = data.block_no_2;
                    this.form.block_no_3 = data.block_no_3;

                    this.form.defect_types = data.defect_types;
                    this.form.cleaning_schedule = data.cleaning_schedule;

                    this.getWorkerCount();

                    if(data && data.signature){
                        this.supervisorSign = {
                            image   : data.signature,
                            name    : 'signature.png',
                            type    : 'supervisor',
                            default : 'yes'
                        }
                    } else {
                        this.supervisorSign = {
                            image   : this.tempSign.image,
                            name    : 'signature.png',
                            type    : 'supervisor',
                            default : 'yes'
                        }
                    }

                    var role = this.$store.getters.currentUser.role;

                    if (data.reject_to == role && ((data.level_one_user_role != role) && (data.level_two_user_role != role) && (data.level_three_user_role != role))) {
                        this.ledit = true;
                    } else if(((role == 'administrator') || (role == 'admin')) && ( (data.level_user_status != null) && (data.level_user_status.role == ''))){
                        this.ledit = true;
                    }
                }
            });

        },
        disabledDate(date){
            return date < moment(new Date(this.form.submitted_date)).subtract(1,'days').tz('Asia/Singapore');
        },
        checkdisabled(){
            if (this.ledit == true) {
                return false;
             }else if(this.disabledOptions == true){
                 return true;
            }else{
                return false;
            }
        }
        },
        mounted(){
            // this.getDefectTypes();
            this.useDefault();
            this.allSites();
            this.siteChange();
            if(this.$route.params.id){
                this.disabledOptions = true;
                this.getFormDetails();
            }
        }
    }
</script>
